/* eslint-disable no-nested-ternary */
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import {
    CircularProgress, FormControl, MenuItem, Paper
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import { LESSON_FILTERS } from '../../resources/constants';
import { ApplicationState } from '../../store/types';
import Button from '../common/button';
import Popper from '../common/popper';
import SelectField from '../common/selectField';
import Title from '../common/title';

/**
 * Parent component that represent a lesson details for the booking page
 */
type ReduxProps = ConnectedProps<typeof connector>;

interface BookingLessonDetailsIProps {
    lesson: {
        price: number;
        id: number;
        titleNumber: string;
        durationSplit?: string;
        startDate: string;
        endDate: string;
        hours: any;
        participants: string;
        infoDays: string;
        infoBeginDays: string;
        infoEndDays: string;
        infoNumberDaysMin: string;
        infoNumberDaysMax: string;
        level: {
            name: string;
            memo: string;
        };
        meetingPoints: {
            name: string;
            memo: string;
        }[];
        type: string;
        firstParameterText: string;
        firstParameterOptions: (typeof MenuItem)[];
        firstParameters: number[];
        secondParameterText: string;
        secondParameterOptions: (typeof MenuItem)[];
        secondParameters: string[];
        meetingPointText?: string;
        meetingPointOptions: any;

    };
    isCheckAvailabilityLoading: boolean;
    hasChoosed: boolean;
    selectedFirstParameter: number;
    selectedSecondParameter: number;
    selectedMeetingPointIndex: number;

    bigScreen: boolean;
    handleChangeFirstParameter(event: any): void;
    handleChangeSecondParameter(event: any): void;
    handleChangeMeetingPoint(event: any): void;
    handleClickButtonSelect(event: any): void;

}
interface BookingLessonDetailsState {
    tooltipInfo: any;
    anchorEl: any;
    tooltipDaysText: any;
}

type BookingLessonDetailsProps = BookingLessonDetailsIProps & ReduxProps & WrappedComponentProps;

class BookingLessonDetails extends React.Component<BookingLessonDetailsProps, BookingLessonDetailsState> {
    constructor(props: BookingLessonDetailsProps) {
        super(props);
        this.state = {
            tooltipInfo: undefined,
            anchorEl: undefined,
            tooltipDaysText: undefined,
        };
    }

    componentDidMount() {
        const { intl, lesson } = this.props;
        // Get all texts
        const weekdays = intl.formatMessage({ id: 'products.weekdays' }).split('_');
        const dayAllowed = intl.formatMessage({ id: 'products.dayAllowed' });
        const startDayAllowed = intl.formatMessage({ id: 'products.startDayAllowed' });
        const endDayAllowed = intl.formatMessage({ id: 'products.endDayAllowed' });
        const nbDays = intl.formatMessage({ id: 'products.nbDays' });
        const {
            infoDays, infoBeginDays, infoEndDays, infoNumberDaysMin, infoNumberDaysMax,
        } = lesson;

        // Get every numeric day [0123456]  => [Monday...Sunday]
        const infoDaysTable = infoDays && infoDays.split('');
        let infoDaysTMP = '';
        // Convert numeric to weekday
        if (Array.isArray(infoDaysTable)) {
            infoDaysTable.forEach((e) => {
                infoDaysTMP += `${weekdays[parseInt(e, 10)]}, `;
            });
        } else {
            infoDaysTMP += weekdays[parseInt(infoDaysTable, 10)];
        }

        // Get every numeric day [0123456] => [Monday...Sunday]
        const infoBeginDaysTable = infoBeginDays && infoBeginDays.split('');
        let infoBeginDaysTMP = '';
        // Convert numeric to weekday
        if (Array.isArray(infoBeginDaysTable)) {
            infoBeginDaysTable.forEach((e) => {
                infoBeginDaysTMP += `${weekdays[parseInt(e, 10)]}, `;
            });
        } else {
            infoBeginDaysTMP += weekdays[parseInt(infoBeginDaysTable, 10)];
        }

        // Get every numeric day [0123456] => [Monday...Sunday]
        const infoEndDaysTable = infoEndDays && infoEndDays.split('');
        let infoEndDaysTMP = '';
        // Convert numeric to weekday
        if (Array.isArray(infoEndDaysTable)) {
            infoEndDaysTable.forEach((e) => {
                infoEndDaysTMP += `${weekdays[parseInt(e, 10)]}, `;
            });
        } else {
            infoEndDaysTMP += weekdays[parseInt(infoEndDaysTable, 10)];
        }

        const tooltipDaysText = `<p><b>${dayAllowed}:</b> ${infoDaysTMP}</p>
                                <p><b>${startDayAllowed}:</b> ${infoBeginDaysTMP}</p>
                                <p><b>${endDayAllowed}:</b> ${infoEndDaysTMP}</p>
                                <p><b>${nbDays}:</b> ${infoNumberDaysMin} - ${infoNumberDaysMax}</p>`;
        this.setState({ tooltipDaysText });
    }

    formatLevelText = (text: string) => {
        const { hasChoosed } = this.props;
        return hasChoosed && text.length > 30 ? `${text.slice(0, 30)}...` : text;
    };

    handleClickInfo = (event: any, item: any) => this.setState(
        {
            tooltipInfo: item,
            anchorEl: event.currentTarget,
        },
    );

    render() {
        const {
            intl,
            lesson,
            bigScreen,
            selectedFirstParameter,
            handleChangeFirstParameter,
            selectedSecondParameter,
            handleChangeSecondParameter,
            selectedMeetingPointIndex,
            handleChangeMeetingPoint,
            isCheckAvailabilityLoading,
            handleClickButtonSelect,
            schoolsList,
            schoolInstructorsConfiguration,
        } = this.props;
        const { tooltipDaysText, tooltipInfo, anchorEl } = this.state;
        return (
            <Paper className="__booking-lesson-paper">
                <div className="__booking-lesson-parent">
                    <div className="__booking-lesson-header" style={{ display: 'flex', alignItems: 'center' }}>
                        <Title className="__booking-lesson-title">
                            {intl.formatMessage({ id: `products.${lesson.type === LESSON_FILTERS.GROUP_LESSON ? 'group' : 'private'}` })}
                            :
                            <span className="__booking-lesson-title-number">{lesson.titleNumber}</span>
                        </Title>
                        <InfoIcon
                            className="__booking-i"
                            style={{ height: '20px', width: '20px' }}
                            onClick={(event) => this.handleClickInfo(event, tooltipDaysText)}
                        />
                    </div>
                    <div className="__booking-lesson-body">
                        <div className="__booking-lesson-1st">
                            <span className="__booking-lesson-1st-info">
                                <p className="__booking-lesson-1st-text">
                                    {intl.formatMessage({ id: 'booking.startDate' })}
                                    :
                                </p>
                                <p className="__booking-lesson-1st-value">
                                    (
                                    {moment(lesson.startDate, 'DD.MM.YYYY').format('dd')}
                                    )
                                    {lesson.startDate}
                                </p>
                            </span>
                            <span className="__booking-lesson-1st-info">
                                <p className="__booking-lesson-1st-text">
                                    {intl.formatMessage({ id: 'booking.endDate' })}
                                    :
                                </p>
                                <p className="__booking-lesson-1st-value">
                                    (
                                    {moment(lesson.endDate, 'DD.MM.YYYY').format('dd')}
                                    )
                                    {lesson.endDate}
                                </p>
                            </span>
                            <span className="__booking-lesson-1st-info">
                                <p className="__booking-lesson-1st-text">
                                    {intl.formatMessage({ id: 'booking.totalHours' })}
                                    :
                                </p>
                                <p className="__booking-lesson-1st-value">{lesson.hours}</p>
                            </span>
                            <span className="__booking-lesson-1st-info">
                                <p className="__booking-lesson-1st-text">
                                    {intl.formatMessage({ id: 'booking.Participants' })}
                                    :
                                </p>
                                <p className="__booking-lesson-1st-value">{lesson.participants}</p>
                            </span>
                            {
                                lesson.durationSplit
                                && (
                                    <span className="__booking-lesson-1st-info">
                                        <p className="__booking-lesson-1st-text">
                                            {intl.formatMessage({ id: 'booking.durationSplit' })}
                                            :
                                        </p>
                                        <p className="__booking-lesson-1st-value">{lesson.durationSplit}</p>
                                    </span>
                                )
                            }
                        </div>
                        <div className="__booking-lesson-divider" style={{ height: bigScreen ? (lesson.durationSplit ? '200px' : '158px') : '1px' }} />
                        <div className="__booking-lesson-2nd">
                            <div className="__booking-lesson-2nd-content">
                                {
                                    lesson.id === 1567 || lesson.id === 1568
                                        ? (
                                            <p className="__booking-lesson-2nd-text">
                                                {intl.formatMessage({ id: 'booking.activity' })}
                                                :
                                            </p>
                                        )
                                        : (
                                            <p className="__booking-lesson-2nd-text">
                                                {intl.formatMessage({ id: 'booking.level' })}
                                                :
                                            </p>
                                        )
                                }
                                {/* <p className="__booking-lesson-2nd-text">{intl.formatMessage({ id: 'booking.level' })}:</p> */}
                                <span className="__booking-lesson-level-container">
                                    <p className="__booking-lesson-2nd-fixed-value">{lesson.level.name.length > 0 ? this.formatLevelText(lesson.level.name) : intl.formatMessage({ id: 'booking.noLevel' })}</p>
                                    {
                                        lesson.level.memo && lesson.level.memo.length > 0
                                        && (
                                            <InfoIcon
                                                className="__booking-i"
                                                style={{ height: '20px', width: '20px' }}
                                                onClick={(event) => this.handleClickInfo(event, lesson.level.memo)}
                                            />
                                        )
                                    }
                                </span>
                            </div>
                            <div className="__booking-lesson-2nd-content">
                                <p className="__booking-lesson-2nd-text">
                                    {lesson.firstParameterText}
                                    :
                                </p>
                                {
                                    lesson.firstParameterOptions.length > 1
                                        ? (
                                            <FormControl style={{ height: '30px', maxWidth: '200px' }}>
                                                <SelectField
                                                    parentClasses="__booking-lesson-select-span"
                                                    value={selectedFirstParameter}
                                                    defaultValue={0}
                                                    onChange={handleChangeFirstParameter}
                                                    name="select_start_time"
                                                    border
                                                    height={35}
                                                >
                                                    {lesson.firstParameterOptions}
                                                </SelectField>
                                            </FormControl>
                                        )
                                        : (
                                            <p className="__booking-lesson-2nd-fixed-value">
                                                {
                                                    lesson.type === LESSON_FILTERS.GROUP_LESSON
                                                        ? moment(lesson.firstParameters[0]).format(`HH[${intl.formatMessage({ id: 'booking.h' })}]mm`)
                                                        : `${lesson.firstParameters[0]} ${intl.formatMessage({ id: 'booking.days' })}`
                                                }
                                            </p>
                                        )
                                }
                            </div>
                            <div className="__booking-lesson-2nd-content">
                                <p className="__booking-lesson-2nd-text">
                                    {lesson.secondParameterText}
                                    :
                                </p>
                                {
                                    lesson.secondParameterOptions.length > 1
                                        ? (
                                            <FormControl style={{ height: '30px', maxWidth: '200px' }}>
                                                <SelectField
                                                    defaultValue={0}
                                                    parentClasses="__booking-lesson-select-span"
                                                    value={selectedSecondParameter}
                                                    onChange={handleChangeSecondParameter}
                                                    name="select_end_time"
                                                    border
                                                    height={35}
                                                >
                                                    {lesson.secondParameterOptions}
                                                </SelectField>
                                            </FormControl>
                                        )
                                        : <p className="__booking-lesson-2nd-fixed-value">{moment(lesson.secondParameters[0]).format(`HH[${intl.formatMessage({ id: 'booking.h' })}]mm`)}</p>
                                }
                            </div>
                            <div className="__booking-lesson-2nd-content">
                                <p className="__booking-lesson-2nd-text">
                                    {intl.formatMessage({ id: 'booking.meeting' })}
                                    :
                                </p>
                                {
                                    lesson.meetingPointOptions.length > 1
                                        ? (
                                            <FormControl style={{
                                                height: '30px', maxWidth: '200px', display: 'flex', flexDirection: 'row', alignItems: 'center'
                                            }}
                                            >
                                                <SelectField
                                                    parentClasses="__booking-lesson-select-span"
                                                    value={selectedMeetingPointIndex}
                                                    onChange={handleChangeMeetingPoint}
                                                    name="select_meeting"
                                                    defaultValue={0}
                                                    border
                                                    height={35}
                                                >
                                                    {lesson.meetingPointOptions}
                                                </SelectField>
                                                {
                                                    selectedMeetingPointIndex === -1
                                                && (
                                                    <WarningIcon
                                                        className="__booking-lesson-warning"
                                                        fontSize="small"
                                                    />
                                                )
                                                }
                                                {
                                                    lesson.meetingPoints[selectedMeetingPointIndex] && lesson.meetingPoints[selectedMeetingPointIndex].memo && lesson.meetingPoints[selectedMeetingPointIndex].memo.length > 0
                                                        ? (
                                                            <InfoIcon
                                                                className="__booking-i"
                                                                style={{ height: '20px', width: '20px' }}
                                                                onClick={(event) => this.handleClickInfo(event, lesson.meetingPoints[selectedMeetingPointIndex].memo)}
                                                            />
                                                        )
                                                        : null
                                                }
                                            </FormControl>
                                        )
                                        : lesson.meetingPointOptions.length === 1
                                            ? (
                                                <span className="__booking-lesson-2nd-fixed-value">
                                                    {lesson.meetingPoints.length > 0 ? lesson.meetingPoints[0].name : ''}
                                                    {
                                                        lesson.meetingPoints[0] && lesson.meetingPoints[0].memo && lesson.meetingPoints[0].memo.length > 0
                                                            ? (
                                                                <InfoIcon
                                                                    className="__booking-i"
                                                                    style={{ height: '20px', width: '20px' }}
                                                                    onClick={(event) => this.handleClickInfo(event, lesson.meetingPoints[0].memo)}
                                                                />
                                                            )
                                                            : null
                                                    }
                                                </span>
                                            )
                                            : <span className="__booking-lesson-2nd-fixed-value" style={{ color: 'red' }}>{intl.formatMessage({ id: 'booking.meetingError' })}</span>
                                }
                            </div>

                        </div>
                        <div className={lesson.meetingPointOptions.length > 1 ? '__booking-lesson-3rd-with-select' : '__booking-lesson-3rd'}>
                            <div>
                                {
                                    lesson.price === -1
                                        ? (
                                            <div className="circular-progress">
                                                <CircularProgress style={{ color: '#3d84c3' }} />
                                            </div>
                                        )
                                        : (
                                            lesson.price === -2 || lesson.meetingPointOptions.length === 0
                                                ? <p className="__booking-lesson-3rd-price">{intl.formatMessage({ id: 'booking.noPrice' })}</p>
                                                : (
                                                    <p className="__booking-lesson-3rd-price">
                                                        CHF
                                                        {' '}
                                                        {lesson.price}
                                                        .-
                                                    </p>
                                                )
                                        )
                                }
                            </div>
                            <div className={isCheckAvailabilityLoading ? '__booking-lesson-3rd-button-loading' : '__booking-lesson-3rd-button'}>
                                <Button
                                    onClick={handleClickButtonSelect}
                                    disabled={lesson.meetingPointOptions.length === 0 || selectedMeetingPointIndex === -1 || lesson.price <= -1 || (schoolsList.length === 0 && lesson.type === LESSON_FILTERS.PRIVATE_LESSON) || (lesson.type === LESSON_FILTERS.PRIVATE_LESSON && !schoolInstructorsConfiguration)}
                                    loading={isCheckAvailabilityLoading}
                                >
                                    {intl.formatMessage({ id: isCheckAvailabilityLoading ? 'order.checking' : 'booking.select' })}
                                </Button>
                            </div>
                        </div>
                        <Popper
                            open={Boolean(tooltipInfo)}
                            onClose={() => this.setState({ tooltipInfo: undefined })}
                            anchorEl={anchorEl}
                            position="top"
                        >
                            <div className="__booking-i-sp">
                                {/* eslint-disable-next-line react/no-danger */}
                                <span dangerouslySetInnerHTML={{ __html: tooltipInfo }} />
                            </div>
                        </Popper>
                    </div>
                </div>
            </Paper>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    windowWidth: store.windowSize.width,
    schoolsList: store.booking.schoolsList,
    schoolInstructorsConfiguration: store.booking.schoolInstructorsConfiguration,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(BookingLessonDetails));
