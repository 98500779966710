const swisskischool = {
    // '--primary-color': '#2a5c88',
    '--primary-color': '#2b6fab',
    '--secondary-color': '#E3001D',
    '--ternary-color': '#595959',
    '--quaternary-color': '#e2eff8',
    '--expandable-header-color': '#e2eff8',
    '--disabled-color': '#9c9c9c',
    '--error-color': '#c40928',
    '--border-color': '#595959',
    '--header-color': '#E3001D',
    '--main-header-color': '#e2eff8',
    '--border-color-inverse': '#595959',
    '--select-options-color': '#e2eff8',
    '--border-top-color': '4px solid #E3001D',
    '--border-top-square': '3px solid #E3001D',

    '--active-menu': '#E3001D',
    '--hover-menu': '#E3001D',
    '--button-color': '#ab9a6b',
    '--square-card-color': '#E3001D',
    '--color-effect': '#E3001D',
    '--hover-color': '#E3001D',
    '--checkbox-color': '#E3001D',

    '--font-title-color': '#E3001D',
    // '--font-title-color': '#2a5c88',
    '--font-title2-color': '#2a5c88',
    '--font-primary-color': '#53535d',
    '--font-primary-color-inverse': '#53535d',
    '--font-header-base-color': '#ffffff',
    // '--font-primary-color-inverse': '#53535d',
    // '--font-primary-color': '#2a5c88',
    '--font-secondary-color': '#E3001D',
    '--font-ternary-color': '#212121',
    '--font-ternary-color-inverse': '#212121',
    '--font-quaternary-color': '#ffffff',
    '--font-square-color': '#ffffff',
    '--font-sitemap-color': '#595959',
    '--font-sitemap-title-color': '#000000',
    '--font-footer-color': '#53535d',
    '--font-input-color': '#53535d',
    '--font-input-color-inverse': '#e2eff8',

    '--background-primary-color': '#fbfbfc',
    '--background-secondary-color': 'white',
    '--background-modal-color': 'white',
    '--background-footer-copyright-color': 'white',
    '--background-sitemap-color': '#fbfbfc',
    '--background-footer-color': '#E3001D',
    '--background-homepage-color': '#ffffff',
    '--background-root-color': '#ecf2f6',
    '--background-topbar-color': '#ecf2f6',
    '--background-select-color': '#212121',
    '--background-search-color': '#373737',

    '--img-to-white': '',

    '--width-half-menu': '488px',
    '--width-container': '1350px',
    '--width-search-bar-schools': '300px',
    '--width-search-bar-date': '200px',
    '--width-search-bar-type': '200px',
    '--width-search-bar-button': 'fit-content',

    '--height-header-image': '650px',
    '--min-height-footer-sponsor': '100px',
    '--display-search-bar': 'flex',
    '--mobile-padding-footer-sponsor': '10px',

    '--main-border-radius': 'unset',
    '--basic-border-radius': '5px',
    '--header-image-border-radius': 'unset',
    '--menubar-border-radius': 'unset',
    '--content-border-radius': 'unset',

    '--header-image-margin-top': '0px',
    '--header-image-min-height': 'initial',
    '--header-image-label-1-text-align-mobile': 'right',
    '--header-image-label-1-font-size': '25px',
    '--header-image-label-1-font-size-mobile': '16.5px',
    '--header-image-label-1-bottom': '75px',
    '--header-image-label-1-bottom-mobile': '37px',
    '--header-image-label-1-width': 'fit-content',
    '--header-image-label-1-width-mobile': 'fit-content',
    '--header-image-label-1-border-radius': '5px 0 0 5px',
    '--header-image-label-1-border-radius-mobile': '5px 0 0 5px',
    '--header-image-label-2-bottom': '22px',
    '--header-image-label-2-bottom-mobile': '5px',
    '--header-image-label-2-display': 'initial',

    '--menubar-height': '73px',
    '--menubar-shadow': '0 1px 5px -1px #9e9e9e17',
    '--menubar-lang-logo-padding-top': '4.5px',
    '--menubar-logo-width': '110px',
    '--menubar-logo-height': '110px',
    '--menubar-logo-margin-top': '12px',
    '--menubar-height-mobile': '95px',
    '--menubar-logo-width-mobile': '100%',
    '--menubar-logo-height-mobile': '105px',
    '--menubar-logo-margin-top-mobile': '5px',

    '--scrollbar-thumb-hover': '#ab9a6b',
    '--scrollbar-thumb-color': '#E3001D',
    '--scrollbar-track-background': '#ecf2f6',

    '--picker-school-diplay': 'flex',
};

const skischoolalliance = {
    '--primary-color': '#e7a806',
    '--secondary-color': '#e7a806',
    // '--ternary-color': '#9c9c9d',
    '--ternary-color': '#c2c1c1',
    '--quaternary-color': '#373737',
    '--expandable-header-color': '#373737',
    '--disabled-color': '#9c9c9c',
    '--error-color': '#c40928',
    '--border-color': '#c2c1c1',
    '--border-color-inverse': '#c2c1c1',
    '--select-options-color': '#373737',
    '--header-color': '#373737',
    '--main-header-color': '#373737',
    '--border-top-color': '4px solid #e7a806',

    '--active-menu': '#e7a806',
    '--hover-menu': '#ffffff',
    '--button-color': '#e7a806',
    '--square-card-color': '#e7a806',
    '--hover-color': '#e7a806',

    '--font-title-color': '#e7a806',
    '--font-title2-color': '#e7a806',
    '--font-primary-color': '#ffffff',
    '--font-primary-color-inverse': '#ffffff',
    '--font-header-base-color': '#ffffff',
    '--font-secondary-color': '#e7a806',
    '--font-ternary-color': '#b6b6b6',
    '--font-ternary-color-inverse': '#b6b6b6',
    '--font-quaternary-color': '#ffffff',
    '--font-square-color': '#ffffff',
    '--font-sitemap-color': '#9c9c9d',
    '--font-sitemap-title-color': '#d2d2d1',
    '--font-footer-color': '#ffffff',
    '--font-input-color': '#ffffff',
    '--font-input-color-inverse': '#373737',

    '--background-primary-color': '#353535',
    '--background-secondary-color': '#212121',
    '--background-modal-color': '#212121',
    '--background-footer-copyright-color': '#212121',
    '--background-sitemap-color': '#212121',
    '--background-footer-color': '#479edd',
    '--background-homepage-color': '#2e2e2e',
    '--background-root-color': '#181818',
    '--background-topbar-color': '#181818',
    '--background-select-color': '#212121',
    '--background-search-color': '#373737',

    '--img-to-white': 'invert(100%) grayscale(100%)',

    '--width-half-menu': '488px',
    '--width-container': '1350px',
    '--width-search-bar-schools': '300px',
    '--width-search-bar-date': '200px',
    '--width-search-bar-type': '200px',
    '--width-search-bar-button': 'fit-content',

    '--height-header-image': '650px',
    '--min-height-footer-sponsor': '100px',
    '--display-search-bar': 'flex',
    '--mobile-padding-footer-sponsor': '10px',

    '--main-border-radius': 'unset',
    '--basic-border-radius': '5px',
    '--header-image-border-radius': 'unset',
    '--menubar-border-radius': 'unset',
    '--content-border-radius': 'unset',

    '--header-image-margin-top': '0px',
    '--header-image-min-height': 'initial',
    '--header-image-label-1-text-align-mobile': 'right',
    '--header-image-label-1-font-size': '25px',
    '--header-image-label-1-font-size-mobile': '16.5px',
    '--header-image-label-1-bottom': '75px',
    '--header-image-label-1-bottom-mobile': '37px',
    '--header-image-label-1-width': 'fit-content',
    '--header-image-label-1-width-mobile': 'fit-content',
    '--header-image-label-1-border-radius': '5px 0 0 5px',
    '--header-image-label-1-border-radius-mobile': '5px 0 0 5px',
    '--header-image-label-2-bottom': '22px',
    '--header-image-label-2-bottom-mobile': '5px',
    '--header-image-label-2-display': 'initial',

    '--menubar-height': '73px',
    '--menubar-shadow': '0 1px 5px -1px #9e9e9e17',
    '--menubar-lang-logo-padding-top': '4.5px',
    '--menubar-logo-width': '110px',
    '--menubar-logo-height': '65px',
    '--menubar-logo-margin-top': '4px',
    '--menubar-height-mobile': '85px',
    '--menubar-logo-width-mobile': '100%',
    '--menubar-logo-height-mobile': '75px',
    '--menubar-logo-margin-top-mobile': '5px',

    '--scrollbar-thumb-hover': '#e7a806',
    '--scrollbar-thumb-color': '#c2c1c1',
    '--scrollbar-track-background': '#181818',

    '--picker-school-diplay': 'flex',
};

const villars = {
    '--primary-color': '#e7a806',
    '--secondary-color': '#e7a806',
    // '--ternary-color': '#9c9c9d',
    '--ternary-color': '#c2c1c1',
    '--quaternary-color': '#373737',
    '--expandable-header-color': '#373737',
    '--disabled-color': '#9c9c9c',
    '--error-color': '#c40928',
    '--border-color': '#c2c1c1',
    '--border-color-inverse': '#c2c1c1',
    '--select-options-color': '#373737',
    '--header-color': '#373737',
    '--main-header-color': '#373737',
    '--border-top-color': '4px solid #e7a806',

    '--active-menu': '#e7a806',
    '--hover-menu': '#ffffff',
    '--button-color': '#e7a806',
    '--square-card-color': '#e7a806',
    '--hover-color': '#e7a806',

    '--font-title-color': '#e7a806',
    '--font-title2-color': '#e7a806',
    '--font-primary-color': '#ffffff',
    '--font-primary-color-inverse': '#ffffff',
    '--font-header-base-color': '#ffffff',
    '--font-secondary-color': '#e7a806',
    '--font-ternary-color': '#b6b6b6',
    '--font-ternary-color-inverse': '#b6b6b6',
    '--font-quaternary-color': '#ffffff',
    '--font-square-color': '#ffffff',
    '--font-sitemap-color': '#9c9c9d',
    '--font-sitemap-title-color': '#d2d2d1',
    '--font-footer-color': '#ffffff',
    '--font-input-color': '#ffffff',
    '--font-input-color-inverse': '#373737',

    '--background-primary-color': '#353535',
    '--background-secondary-color': '#212121',
    '--background-modal-color': '#212121',
    '--background-footer-copyright-color': '#212121',
    '--background-sitemap-color': '#212121',
    '--background-footer-color': '#e7a806',
    '--background-homepage-color': '#2e2e2e',
    '--background-root-color': '#181818',
    '--background-topbar-color': '#181818',
    '--background-select-color': '#212121',
    '--background-search-color': '#373737',

    '--img-to-white': 'invert(100%)',

    '--width-half-menu': '488px',
    '--width-container': '1350px',
    '--width-search-bar-schools': '300px',
    '--width-search-bar-date': '200px',
    '--width-search-bar-type': '200px',
    '--width-search-bar-button': 'fit-content',

    '--height-header-image': '650px',
    '--min-height-footer-sponsor': '100px',
    '--display-search-bar': 'flex',
    '--mobile-padding-footer-sponsor': '10px',

    '--main-border-radius': 'unset',
    '--basic-border-radius': '5px',
    '--header-image-border-radius': 'unset',
    '--menubar-border-radius': 'unset',
    '--content-border-radius': 'unset',

    '--header-image-margin-top': '0px',
    '--header-image-min-height': 'initial',
    '--header-image-label-1-text-align-mobile': 'right',
    '--header-image-label-1-font-size': '25px',
    '--header-image-label-1-font-size-mobile': '16.5px',
    '--header-image-label-1-bottom': '75px',
    '--header-image-label-1-bottom-mobile': '37px',
    '--header-image-label-1-width': 'fit-content',
    '--header-image-label-1-width-mobile': 'fit-content',
    '--header-image-label-1-border-radius': '5px 0 0 5px',
    '--header-image-label-1-border-radius-mobile': '5px 0 0 5px',
    '--header-image-label-2-bottom': '22px',
    '--header-image-label-2-bottom-mobile': '5px',
    '--header-image-label-2-display': 'initial',

    '--menubar-height': '73px',
    '--menubar-shadow': '0 1px 5px -1px #9e9e9e17',
    '--menubar-lang-logo-padding-top': '4.5px',
    '--menubar-logo-width': '110px',
    '--menubar-logo-height': '110px',
    '--menubar-logo-margin-top': '-16px',
    '--menubar-height-mobile': '85px',
    '--menubar-logo-width-mobile': '100%',
    '--menubar-logo-height-mobile': '75px',
    '--menubar-logo-margin-top-mobile': '5px',

    '--scrollbar-thumb-hover': '#e7a806',
    '--scrollbar-thumb-color': '#c2c1c1',
    '--scrollbar-track-background': '#181818',

    '--picker-school-diplay': 'flex',
};

const diablerets = {
    '--primary-color': '#56a9dc',
    '--secondary-color': '#2daae2',
    // '--ternary-color': '#9c9c9d',
    '--ternary-color': '#c2c1c1',
    '--quaternary-color': '#373737',
    '--expandable-header-color': '#373737',
    '--disabled-color': '#9c9c9c',
    '--error-color': '#c40928',
    '--border-color': '#c2c1c1',
    '--border-color-inverse': '#c2c1c1',
    '--select-options-color': '#373737',
    '--header-color': '#373737',
    '--main-header-color': '#373737',
    '--border-top-color': '4px solid #2daae2',

    '--active-menu': '#56a9dc',
    '--hover-menu': '#ffffff',
    '--button-color': '#56a9dc',
    '--square-card-color': '#56a9dc',
    '--hover-color': '#56a9dc',

    '--font-title-color': '#56a9dc',
    '--font-title2-color': '#56a9dc',
    '--font-primary-color': '#ffffff',
    '--font-primary-color-inverse': '#ffffff',
    '--font-header-base-color': '#ffffff',
    '--font-secondary-color': '#56a9dc',
    '--font-ternary-color': '#b6b6b6',
    '--font-ternary-color-inverse': '#b6b6b6',
    '--font-quaternary-color': '#ffffff',
    '--font-square-color': '#ffffff',
    '--font-sitemap-color': '#9c9c9d',
    '--font-sitemap-title-color': '#d2d2d1',
    '--font-footer-color': '#ffffff',
    '--font-input-color': '#ffffff',
    '--font-input-color-inverse': '#373737',

    '--background-primary-color': '#353535',
    '--background-secondary-color': '#212121',
    '--background-modal-color': '#212121',
    '--background-footer-copyright-color': '#212121',
    '--background-sitemap-color': '#212121',
    '--background-footer-color': '#479edd',
    '--background-homepage-color': '#2e2e2e',
    '--background-root-color': '#181818',
    '--background-topbar-color': '#181818',
    '--background-select-color': '#212121',
    '--background-search-color': '#373737',

    '--img-to-white': 'invert(100%)',

    '--width-half-menu': '488px',
    '--width-container': '1350px',
    '--width-search-bar-schools': '300px',
    '--width-search-bar-date': '200px',
    '--width-search-bar-type': '200px',
    '--width-search-bar-button': 'fit-content',

    '--height-header-image': '650px',
    '--min-height-footer-sponsor': '100px',
    '--display-search-bar': 'flex',
    '--mobile-padding-footer-sponsor': '10px',

    '--main-border-radius': 'unset',
    '--basic-border-radius': '5px',
    '--header-image-border-radius': 'unset',
    '--menubar-border-radius': 'unset',
    '--content-border-radius': 'unset',

    '--header-image-margin-top': '0px',
    '--header-image-min-height': 'initial',
    '--header-image-label-1-text-align-mobile': 'right',
    '--header-image-label-1-font-size': '25px',
    '--header-image-label-1-font-size-mobile': '16.5px',
    '--header-image-label-1-bottom': '75px',
    '--header-image-label-1-bottom-mobile': '37px',
    '--header-image-label-1-width': 'fit-content',
    '--header-image-label-1-width-mobile': 'fit-content',
    '--header-image-label-1-border-radius': '5px 0 0 5px',
    '--header-image-label-1-border-radius-mobile': '5px 0 0 5px',
    '--header-image-label-2-bottom': '22px',
    '--header-image-label-2-bottom-mobile': '5px',
    '--header-image-label-2-display': 'initial',

    '--menubar-height': '73px',
    '--menubar-shadow': '0 1px 5px -1px #9e9e9e17',
    '--menubar-lang-logo-padding-top': '4.5px',
    '--menubar-logo-width': '110px',
    '--menubar-logo-height': '90px',
    '--menubar-logo-margin-top': '15px',
    '--menubar-height-mobile': '85px',
    '--menubar-logo-width-mobile': '100%',
    '--menubar-logo-height-mobile': '75px',
    '--menubar-logo-margin-top-mobile': '5px',

    '--scrollbar-thumb-hover': '#56a9dc',
    '--scrollbar-thumb-color': '#c2c1c1',
    '--scrollbar-track-background': '#181818',

    '--picker-school-diplay': 'flex',

};

const bookingsport = {
    '--primary-color': '#d98909',
    '--secondary-color': '#d98909',
    '--ternary-color': '#c2c1c1',
    '--quaternary-color': '#303e45',
    '--expandable-header-color': '#303e45',
    // '--quaternary-color': '#37474f',
    // '--quaternary-color': '#263238',
    '--disabled-color': '#9c9c9c',
    '--error-color': '#c40928',
    '--border-color': '#c2c1c1',
    '--border-color-inverse': '#c2c1c1',
    '--select-options-color': '#303e45',
    '--header-color': '#303e45',
    '--main-header-color': '#303e45',
    '--border-top-color': 'none',

    '--active-menu': '#d98909',
    '--hover-menu': '#ffffff',
    '--button-color': '#d98909',
    '--square-card-color': '#d98909',
    '--hover-color': '#d98909',

    '--font-title-color': '#eceff1',
    '--font-title2-color': '#eceff1',
    '--font-primary-color': '#ffffff',
    '--font-primary-color-inverse': '#ffffff',
    '--font-header-base-color': '#ffffff',
    '--font-secondary-color': '#eceff1',
    '--font-ternary-color': '#b6b6b6',
    '--font-ternary-color-inverse': '#b6b6b6',
    '--font-quaternary-color': '#ffffff',
    '--font-square-color': '#ffffff',
    '--font-sitemap-color': '#c2c1c1',
    '--font-sitemap-title-color': 'white',
    '--font-footer-color': '#ffffff',
    '--font-input-color': '#ffffff',
    '--font-input-color-inverse': '#303e45',

    '--background-primary-color': '#455a64',
    '--background-secondary-color': '#303e45',
    '--background-modal-color': '#303e45',
    '--background-footer-copyright-color': '#263238',
    '--background-sitemap-color': '#303e45',
    // '--background-sitemap-color': '#263238',
    '--background-footer-color': '#d98909',
    '--background-homepage-color': '#37474f',
    '--background-root-color': '#263238',
    '--background-topbar-color': '#263238',
    '--background-select-color': '#718792',
    '--background-search-color': '#263238',

    '--img-to-white': 'invert(100%)',

    '--width-half-menu': '488px',
    '--width-container': '1500px',
    '--width-search-bar-schools': '300px',
    '--width-search-bar-date': '200px',
    '--width-search-bar-type': '200px',
    '--width-search-bar-button': 'fit-content',

    '--height-header-image': '700px',
    '--min-height-footer-sponsor': '0px',
    '--display-search-bar': 'flex',
    '--mobile-padding-footer-sponsor': '0px',

    '--main-border-radius': 'unset',
    '--basic-border-radius': '15px',
    '--header-image-border-radius': '15px 15px 0 0',
    '--menubar-border-radius': '15px 15px 0px 0px',
    // '--menubar-border-radius': 'unset',
    '--content-border-radius': '0 0 15px 15px',

    // '--header-image-margin-top' : '-20px',
    '--header-image-margin-top': 'initial',
    '--header-image-min-height': '250px',
    '--header-image-label-1-text-align-mobile': 'center',
    '--header-image-label-1-font-size': '28px',
    '--header-image-label-1-font-size-mobile': '16.5px',
    '--header-image-label-1-bottom': '40px',
    '--header-image-label-1-bottom-mobile': '15px',
    '--header-image-label-1-width': 'fit-content',
    '--header-image-label-1-width-mobile': '100%',
    '--header-image-label-1-border-radius': '15px 0 0 15px',
    '--header-image-label-1-border-radius-mobile': 'unset',
    '--header-image-label-2-bottom': '30px',
    '--header-image-label-2-bottom-mobile': '25px',
    '--header-image-label-2-display': 'none',

    '--menubar-height': '80px',
    '--menubar-shadow': 'none',
    '--menubar-lang-logo-padding-top': '8px',
    '--menubar-logo-width': 'fit-content',
    '--menubar-logo-height': '50px',
    '--menubar-logo-margin-top': '15px',
    '--menubar-height-mobile': '80px',
    '--menubar-logo-width-mobile': '100%',
    '--menubar-logo-height-mobile': '50px',
    '--menubar-logo-margin-top-mobile': '15px',

    '--scrollbar-thumb-hover': '#d98909',
    '--scrollbar-thumb-color': '#eceff1',
    '--scrollbar-track-background': '#263238',

    '--picker-school-diplay': 'none',
};

const bookingsportRide2peak = {
    '--primary-color': '#ea5b0c',
    '--secondary-color': '#ea5b0c',
    // '--ternary-color': '#595959',
    '--ternary-color': '#595959',
    '--quaternary-color': '#2f5599',
    '--expandable-header-color': '#2f5599',
    // '--quaternary-color': '#37474f',
    // '--quaternary-color': '#263238',
    '--disabled-color': '#9c9c9c',
    '--error-color': '#c40928',
    '--border-color': '#2f5599',
    '--border-color-inverse': 'white',
    '--select-options-color': 'white',
    '--header-color': '#2a4c8a',
    '--main-header-color': '#2a4c8a',
    '--border-top-color': 'none',

    '--active-menu': '#ea5b0c',
    '--hover-menu': '#ffffff',
    '--button-color': '#ea5b0c',
    '--square-card-color': '#ea5b0c',
    '--color-effect': '#ea5b0c',
    '--hover-color': '#ea5b0c',

    '--font-title-color': '#2f5599',
    '--font-title2-color': '#2f5599',
    '--font-primary-color': '#595959',
    '--font-primary-color-inverse': '#ffffff',
    '--font-header-base-color': '#ffffff',
    '--font-secondary-color': '#eceff1',
    '--font-ternary-color': '#212121',
    '--font-ternary-color-inverse': 'white',
    '--font-quaternary-color': '#ffffff',
    '--font-square-color': '#ffffff',
    '--font-sitemap-color': '#c2c1c1',
    '--font-sitemap-title-color': 'white',
    '--font-footer-color': '#2f5599',
    '--font-input-color': '#2f5599',
    '--font-input-color2': 'transparent',
    '--font-input-color-inverse': 'white',

    '--background-primary-color': '#f0f0f0',
    '--background-secondary-color': '#2f5599',
    '--background-modal-color': 'white',
    '--background-sitemap-color': '#2f5599',
    // '--background-sitemap-color': '#263238',
    '--background-footer-color': '#ea5b0c',
    '--background-homepage-color': 'white',
    '--background-root-color': 'whitesmoke',
    '--background-footer-copyright-color': 'whitesmoke',
    '--background-topbar-color': 'whitesmoke',
    '--background-select-color': '#718792',
    '--background-search-color': '#263238',

    '--img-to-white': 'invert(100%)',

    '--width-half-menu': '488px',
    '--width-container': '1500px',
    '--width-search-bar-schools': '300px',
    '--width-search-bar-date': '200px',
    '--width-search-bar-type': '200px',
    '--width-search-bar-button': 'fit-content',

    '--height-header-image': '700px',
    '--min-height-footer-sponsor': '95px',
    '--display-search-bar': 'flex',
    '--mobile-padding-footer-sponsor': '0px',

    '--main-border-radius': 'unset',
    '--basic-border-radius': '15px',
    '--header-image-border-radius': '15px 15px 0 0',
    '--menubar-border-radius': '15px 15px 0px 0px',
    // '--menubar-border-radius': 'unset',
    '--content-border-radius': '0 0 15px 15px',

    // '--header-image-margin-top' : '-20px',
    '--header-image-margin-top': 'initial',
    '--header-image-min-height': '250px',
    '--header-image-label-1-text-align-mobile': 'center',
    '--header-image-label-1-font-size': '28px',
    '--header-image-label-1-font-size-mobile': '16.5px',
    '--header-image-label-1-bottom': '40px',
    '--header-image-label-1-bottom-mobile': '15px',
    '--header-image-label-1-width': 'fit-content',
    '--header-image-label-1-width-mobile': '100%',
    '--header-image-label-1-border-radius': '15px 0 0 15px',
    '--header-image-label-1-border-radius-mobile': 'unset',
    '--header-image-label-2-bottom': '30px',
    '--header-image-label-2-bottom-mobile': '25px',
    '--header-image-label-2-display': 'none',

    '--menubar-height': '80px',
    '--menubar-shadow': 'none',
    '--menubar-lang-logo-padding-top': '8px',
    '--menubar-logo-width': 'fit-content',
    '--menubar-logo-height': '120px',
    '--menubar-logo-margin-top': '15px',
    '--menubar-height-mobile': '80px',
    '--menubar-logo-width-mobile': '100%',
    '--menubar-logo-height-mobile': '75px',
    '--menubar-logo-margin-top-mobile': '4px',

    '--scrollbar-thumb-hover': '#ea5b0c',
    '--scrollbar-thumb-color': '#2f5599',
    '--scrollbar-track-background': 'whitesmoke',

    '--picker-school-diplay': 'none',
};

const bookingformation = {
    '--primary-color': '#7EA3CC',
    '--secondary-color': '#1f325c',
    '--ternary-color': '#4a4a4a',
    '--quaternary-color': '#E2EFF8',
    '--expandable-header-color': '#E2EFF8',
    '--disabled-color': '#BEBEBE',
    '--error-color': '#E83A59',
    '--border-color': '#A5A5A5',
    '--header-color': '#1f325c',
    '--main-header-color': '#1f325c',
    '--border-color-inverse': '#A5A5A5',
    '--select-options-color': '#E2EFF8',
    '--border-top-color': '4px solid #1f325c',
    '--border-top-square': '3px solid #1f325c',
    '--active-menu': '#1f325c',
    '--hover-menu': '#1f325c',
    '--button-color': '#5D9CBF',
    '--square-card-color': '#5D9CBF',
    '--color-effect': '#1f325c',
    '--hover-color': '#1f325c',
    '--checkbox-color': '#1f325c',
    '--font-title-color': '#1f325c',
    '--font-title2-color': '#7EA3CC',
    '--font-primary-color': '#53535D',
    '--font-primary-color-inverse': '#53535D',
    '--font-header-base-color': '#FFFFFF',
    '--font-secondary-color': '#1f325c',
    '--font-ternary-color': '#212121',
    '--font-ternary-color-inverse': '#212121',
    '--font-quaternary-color': '#FFFFFF',
    '--font-square-color': '#FFFFFF',
    '--font-sitemap-color': '#A5A5A5',
    '--font-sitemap-title-color': '#000000',
    '--font-footer-color': '#53535D',
    '--font-input-color': '#53535D',
    '--font-input-color-inverse': '#E2EFF8',
    '--background-primary-color': '#FBFBFC',
    '--background-secondary-color': '#FFFFFF',
    '--background-modal-color': '#FFFFFF',
    '--background-footer-copyright-color': '#FFFFFF',
    '--background-sitemap-color': '#FBFBFC',
    '--background-footer-color': '#1f325c',
    '--background-homepage-color': '#FFFFFF',
    '--background-root-color': '#F6F4F2',
    '--background-topbar-color': '#FBFBFC',
    '--background-select-color': '#212121',
    '--background-search-color': '#373737',
    '--img-to-white': '100%',
    '--width-half-menu': '488px',
    '--width-container': '1500px',
    '--width-search-bar-schools': '300px',
    '--width-search-bar-date': '200px',
    '--width-search-bar-type': '200px',
    '--width-search-bar-button': 'fit-content',
    '--height-header-image': '650px',
    '--min-height-footer-sponsor': '100px',
    '--display-search-bar': 'flex',
    '--mobile-padding-footer-sponsor': '10px',
    '--main-border-radius': '10px',
    '--basic-border-radius': '10px',
    '--header-image-border-radius': '0 0 10px 10px',
    '--menubar-border-radius': 'unset',
    '--content-border-radius': '10px',
    '--header-image-margin-top': '0px',
    '--header-image-min-height': 'initial',
    '--header-image-label-1-text-align-mobile': 'right',
    '--header-image-label-1-font-size': '25px',
    '--header-image-label-1-font-size-mobile': '16.5px',
    '--header-image-label-1-bottom': '75px',
    '--header-image-label-1-bottom-mobile': '37px',
    '--header-image-label-1-width': 'fit-content',
    '--header-image-label-1-width-mobile': 'fit-content',
    '--header-image-label-1-border-radius': '10px 0 0 10px',
    '--header-image-label-1-border-radius-mobile': '10px 0 0 10px',
    '--header-image-label-2-bottom': '22px',
    '--header-image-label-2-bottom-mobile': '5px',
    '--header-image-label-2-display': 'initial',
    '--menubar-height': '73px',
    '--menubar-shadow': '0 1px 5px -1px #9E9E9E17',
    '--menubar-lang-logo-padding-top': '4.5px',
    '--menubar-logo-width': '110px',
    '--menubar-logo-height': '110px',
    '--menubar-logo-margin-top': '12px',
    '--menubar-height-mobile': '95px',
    '--menubar-logo-width-mobile': '100%',
    '--menubar-logo-height-mobile': '105px',
    '--menubar-logo-margin-top-mobile': '5px',
    '--scrollbar-thumb-hover': '#5D9CBF',
    '--scrollbar-thumb-color': '#1f325c',
    '--scrollbar-track-background': '#F6F4F2',
    '--picker-school-diplay': 'flex',
};

const bookingsportEvolution = {
    '--primary-color': '#ef5114',
    '--secondary-color': '#ef5114',
    '--ternary-color': '#c2c1c1',
    '--quaternary-color': '#303e45',
    '--expandable-header-color': '#303e45',
    // '--quaternary-color': '#37474f',
    // '--quaternary-color': '#263238',
    '--disabled-color': '#9c9c9c',
    '--error-color': '#c40928',
    '--border-color': '#c2c1c1',
    '--border-color-inverse': '#c2c1c1',
    '--select-options-color': '#303e45',
    '--header-color': '#303e45',
    '--main-header-color': '#303e45',
    '--border-top-color': 'none',

    '--active-menu': '#ef5114',
    '--hover-menu': '#ffffff',
    '--button-color': '#ef5114',
    '--square-card-color': '#ef5114',
    '--hover-color': '#ef5114',

    '--font-title-color': '#eceff1',
    '--font-title2-color': '#eceff1',
    '--font-primary-color': '#ffffff',
    '--font-primary-color-inverse': '#ffffff',
    '--font-header-base-color': '#ffffff',
    '--font-secondary-color': '#eceff1',
    '--font-ternary-color': '#b6b6b6',
    '--font-ternary-color-inverse': '#b6b6b6',
    '--font-quaternary-color': '#ffffff',
    '--font-square-color': '#ffffff',
    '--font-sitemap-color': '#c2c1c1',
    '--font-sitemap-title-color': 'white',
    '--font-footer-color': '#ffffff',
    '--font-input-color': '#ffffff',
    '--font-input-color-inverse': '#303e45',

    '--background-primary-color': '#455a64',
    '--background-secondary-color': '#303e45',
    '--background-modal-color': '#303e45',
    '--background-footer-copyright-color': '#263238',
    '--background-sitemap-color': '#303e45',
    // '--background-sitemap-color': '#263238',
    '--background-footer-color': '#ef5114',
    '--background-homepage-color': '#37474f',
    '--background-root-color': '#263238',
    '--background-topbar-color': '#263238',
    '--background-select-color': '#718792',
    '--background-search-color': '#263238',

    '--img-to-white': 'invert(100%)',

    '--width-half-menu': '488px',
    '--width-container': '1500px',
    '--width-search-bar-schools': '300px',
    '--width-search-bar-date': '200px',
    '--width-search-bar-type': '200px',
    '--width-search-bar-button': 'fit-content',

    '--height-header-image': '700px',
    '--min-height-footer-sponsor': '0px',
    '--display-search-bar': 'flex',
    '--mobile-padding-footer-sponsor': '0px',

    '--main-border-radius': 'unset',
    '--basic-border-radius': '15px',
    '--header-image-border-radius': '15px 15px 0 0',
    '--menubar-border-radius': '15px 15px 0px 0px',
    // '--menubar-border-radius': 'unset',
    '--content-border-radius': '0 0 15px 15px',

    // '--header-image-margin-top' : '-20px',
    '--header-image-margin-top': 'initial',
    '--header-image-min-height': '250px',
    '--header-image-label-1-text-align-mobile': 'center',
    '--header-image-label-1-font-size': '28px',
    '--header-image-label-1-font-size-mobile': '16.5px',
    '--header-image-label-1-bottom': '40px',
    '--header-image-label-1-bottom-mobile': '15px',
    '--header-image-label-1-width': 'fit-content',
    '--header-image-label-1-width-mobile': '100%',
    '--header-image-label-1-border-radius': '15px 0 0 15px',
    '--header-image-label-1-border-radius-mobile': 'unset',
    '--header-image-label-2-bottom': '30px',
    '--header-image-label-2-bottom-mobile': '25px',
    '--header-image-label-2-display': 'none',

    '--menubar-height': '100px',
    '--menubar-shadow': 'none',
    '--menubar-lang-logo-padding-top': '8px',
    '--menubar-logo-width': 'fit-content',
    '--menubar-logo-height': '70px',
    '--menubar-logo-margin-top': '15px',
    '--menubar-height-mobile': '80px',
    '--menubar-logo-width-mobile': '100%',
    '--menubar-logo-height-mobile': '50px',
    '--menubar-logo-margin-top-mobile': '15px',

    '--scrollbar-thumb-hover': '#ef5114',
    '--scrollbar-thumb-color': '#eceff1',
    '--scrollbar-track-background': '#263238',

    '--picker-school-diplay': 'none',
};

const themes = [swisskischool, skischoolalliance, villars, diablerets, bookingsport, bookingsportRide2peak, bookingformation, bookingsportEvolution];
export default themes;
