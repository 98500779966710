import InfoIcon from '@mui/icons-material/Info';
import * as Sentry from '@sentry/browser';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { OPTIONS_DEPENDENCES, SMARTPHONE_WIDTH } from '../../resources/constants';
import { setSelectedOptions } from '../../store/actions/booking';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { FormattedOption, Option } from '../../types/booking';
import Button from '../common/button';
import CheckboxControl from '../common/checkboxControl';
import LoadingCircle from '../common/loadingCircle';
import Numberfield from '../common/numberfield';
import Popper from '../common/popper';
import Tooltip from '../common/tooltip';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch
    handleExpandable: () => void;
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    formatedOptions: React.ReactNode;
    optionInfo: any;
    numberfieldErrors: any;
    anchorEl?: any;
}

class Options extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            formatedOptions: [],
            optionInfo: undefined,
            numberfieldErrors: undefined,
        };
    }

    componentDidMount() {
        this.createRef();
    }

    componentDidUpdate(prevProps: Props) {
        const { options } = this.props;
        if (prevProps.options !== options) {
            this.createRef();
        }
    }

    handleOptionCheckBox = (checkbox: any, id: string) => {
        const cbRef = this[`optionscb_${id}`].current;
        cbRef.checked = checkbox.checked;
    };

    handleClickNext = () => {
        const { options, handleExpandable } = this.props;
        let error = false;
        const errors: any = [];
        options.forEach((option) => {
            const cbRef = this[`optionscb_${option.id}`].current;
            if (cbRef.checked) {
                if (this[`optionstf_${option.id}`] !== undefined && this[`optionstf_${option.id}`].current.state !== undefined && this[`optionstf_${option.id}`].current.state.value !== undefined && this[`optionstf_${option.id}`].current.state.value.length === 0) {
                    errors.push(true);
                    error = true;
                } else {
                    errors.push(false);
                }
            } else if (option.obligatory) {
                errors.push(true);
                error = true;
            } else {
                errors.push(false);
            }
        });

        if (error) {
            this.setState({ numberfieldErrors: errors }, () => this.setState({ formatedOptions: this.formatOptions() }));
            return;
        }
        this.setState({ numberfieldErrors: undefined }, () => this.setState({ formatedOptions: this.formatOptions() }));

        this.setSelection();
        handleExpandable();
    };

    setSelection = () => {
        const selectedOptions: FormattedOption[] = [];
        const { options, dispatch } = this.props;

        options.forEach((option) => {
            const cbRef = this[`optionscb_${option.id}`].current;
            if (cbRef.checked) {
                const opt: FormattedOption = {
                    id: option.id, qty: -1, text: option.text, price: (typeof option.price === 'string' ? parseFloat(option.price) : option.price), type: option.type
                };
                // TO DO check qty + add total
                if (this[`optionstf_${option.id}`] !== undefined) {
                    const { value } = this[`optionstf_${option.id}`].current.state;
                    opt.qty = parseFloat(value);
                    // eslint-disable-next-line no-param-reassign
                    option.qty = opt.qty;
                }
                const tt = this.computeTotalPrice(option);
                opt.total = tt ? parseFloat(tt) : 0;
                selectedOptions.push(opt);
            }
        });

        dispatch(setSelectedOptions(selectedOptions));
    };

    createRef = () => {
        const { options } = this.props;
        if (options.length === 0) {
            this.setState({ formatedOptions: <div>No options available</div> }); // TODO: pointless because if there is no option the expandable doesn't show up.
        } else {
            // create ref dynamically
            options.forEach((option) => {
                this[`optionscb_${option.id}`] = React.createRef();
                if (option.need_qte) this[`optionstf_${option.id}`] = React.createRef();
            });

            this.setState({ formatedOptions: this.formatOptions() });
        }
    };

    displayTotalPrice = (option: Option) => {
        const { intl, lessonDetails } = this.props;
        const price = (typeof option.price === 'string' ? parseFloat(option.price) : option.price);
        const { formatMessage } = intl;
        if (lessonDetails == null) {
            Sentry.captureMessage('Error undefined - missing lessonDetails');
            return 'Error undefined';
        }
        switch (option.type) {
            case OPTIONS_DEPENDENCES.DAYS: {
                const days = Array.isArray(lessonDetails.days) ? lessonDetails.days[lessonDetails.selectedDayIndex] : lessonDetails.days;
                return `${price} * ${days} ${formatMessage({ id: `booking.day${days > 1 ? 's' : ''}` })} = ${price * days}.-`;
            }
            case OPTIONS_DEPENDENCES.PARTICIPANTS:
                return `${price} * ${lessonDetails.participants} ${formatMessage({ id: `booking.participant${lessonDetails.participants > 1 ? 's' : ''}` })} = ${price * lessonDetails.participants}.-`;
            case OPTIONS_DEPENDENCES.DAYS_AND_PARTICIPANTS: {
                const d = Array.isArray(lessonDetails.days) ? lessonDetails.days[lessonDetails.selectedDayIndex] : lessonDetails.days;
                return `${price} * ${d} ${formatMessage({ id: `booking.day${d > 1 ? 's' : ''}` })} * ${lessonDetails.participants} ${formatMessage({ id: `booking.participant${lessonDetails.participants > 1 ? 's' : ''}` })} = ${price * d * lessonDetails.participants}.-`;
            }
            case OPTIONS_DEPENDENCES.FIX:
                return '';
            case OPTIONS_DEPENDENCES.PERCENT:
                return `${lessonDetails.price} + ${price}% = ${lessonDetails.price + (lessonDetails.price * price * 0.01)}`;
            case OPTIONS_DEPENDENCES.QUANTITY:
                return `${price}.- * ${intl.formatMessage({ id: 'booking.quantity' })}`;
            default:
                Sentry.captureMessage(`Error undefined - default case displayTotalPrice, type: ${option.type}`);
                return 'Error undefined';
        }
    };

    computeTotalPrice = (option: Option) => {
        const { lessonDetails } = this.props;
        const price = (typeof option.price === 'string' ? parseFloat(option.price) : option.price);
        if (lessonDetails == null) {
            Sentry.captureMessage('Error undefined - missing lessonDetails');
            return undefined;
        }
        switch (option.type) {
            case OPTIONS_DEPENDENCES.DAYS: {
                const days = Array.isArray(lessonDetails.days) ? lessonDetails.days[lessonDetails.selectedDayIndex] : lessonDetails.days;
                return (price * days).toFixed(2);
            }
            case OPTIONS_DEPENDENCES.PARTICIPANTS:
                return (price * lessonDetails.participants).toFixed(2);
            case OPTIONS_DEPENDENCES.DAYS_AND_PARTICIPANTS: {
                const d = Array.isArray(lessonDetails.days) ? lessonDetails.days[lessonDetails.selectedDayIndex] : lessonDetails.days;
                return (price * d * lessonDetails.participants).toFixed(2);
            }
            case OPTIONS_DEPENDENCES.FIX:
                return price.toFixed(2);
            case OPTIONS_DEPENDENCES.PERCENT:
                return (lessonDetails.price * price * 0.01).toFixed(2);
            case OPTIONS_DEPENDENCES.QUANTITY:
                // option.qty sometimes undefined , it's a bug ?
                return option.qty ? (price * option.qty).toFixed(2) : price.toFixed(2);
            default:
                Sentry.captureMessage('Error undefined - computeTotalPrice');
                return undefined;
        }
    };

    formatOptions = () => {
        const {
            options, intl, windowWidth, lesson,
        } = this.props;
        const { numberfieldErrors } = this.state;
        return options.map((option, i) => {
            const info = (
                <span>
                    {
                        option.description
                        && (
                            <span>
                                <p>{option.description}</p>
                                <br />
                            </span>
                        )
                    }
                    <p>{`${intl.formatMessage({ id: `booking.${option.type}` })}`}</p>
                    <br />
                    <p>{`${this.displayTotalPrice(option)}`}</p>
                </span>
            );

            const tooltip = (
                <Tooltip title={info}>
                    <InfoIcon style={{ height: '20px', width: '20px' }} className="__booking-i" onClick={(event) => this.setState({ optionInfo: windowWidth <= SMARTPHONE_WIDTH ? option : undefined, anchorEl: event.currentTarget })} />
                </Tooltip>
            );

            return (
                <div className="__booking-options-left-content" key={`options_${option.id}`}>
                    <CheckboxControl
                        defaultChecked={lesson?.school.external_id === '2' && option.id === 'OHC3'}
                        classNameCheckBox="__booking-options-checkbox"
                        inputClasses="__booking-options-checkbox-input"
                        labelClasses="__booking-options-checkbox-label"
                        // value={option.id}
                        label={`${option.text} (CHF ${this.computeTotalPrice(option)})`}
                        onChange={(checkbox) => this.handleOptionCheckBox(checkbox, option.id)}
                        inputRef={this[`optionscb_${option.id}`]}
                        inputFirst
                        error={option.obligatory && numberfieldErrors && numberfieldErrors[i] ? intl.formatMessage({ id: 'booking.noChecked' }) : undefined}
                    />
                    {option.need_qte ? null : tooltip}
                    {
                        option.need_qte
                            ? (
                                <div className="__booking-options-left-content">
                                    <Numberfield
                                        containerClasses="__booking-options-numberfield"
                                        min={option.qte_min}
                                        max={option.qte_max > 0 ? option.qte_max : undefined}
                                        asText
                                        defaultValue={option.qte_min}
                                        ref={this[`optionstf_${option.id}`]}
                                        error={numberfieldErrors && numberfieldErrors[i] ? intl.formatMessage({ id: 'booking.error' }) : undefined}
                                    />
                                    {tooltip}
                                </div>
                            )
                            : null
                    }
                </div>
            );
        });
    };

    [key: string]: any;

    render() {
        const { options, windowWidth, intl } = this.props;
        const { formatedOptions, optionInfo, anchorEl } = this.state;
        return (
            <div className="__booking-options-parent">
                <div className="__booking-options-left">
                    {
                        options.length > 0
                            ? (
                                <div className="__booking-options-content">
                                    {formatedOptions}
                                </div>
                            )
                            : <LoadingCircle />
                    }
                    {
                        windowWidth <= SMARTPHONE_WIDTH && optionInfo !== undefined
                            ? (
                                <Popper
                                    open={Boolean(optionInfo)}
                                    anchorEl={anchorEl}
                                    onClose={() => this.setState({ optionInfo: undefined, anchorEl: undefined })}
                                >

                                    <div className="__booking-i-sp">
                                        <span>
                                            <p>{`${optionInfo.description}`}</p>
                                            <br />
                                            <p>{`${intl.formatMessage({ id: `booking.${optionInfo.type}` })}`}</p>
                                            <br />
                                            <p>{`${this.displayTotalPrice(optionInfo)}`}</p>
                                        </span>
                                    </div>
                                </Popper>
                            )
                            : null
                    }
                </div>
                <div className="__booking-options-button">
                    <Button onClick={this.handleClickNext}>
                        {intl.formatMessage({ id: 'booking.next' })}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    lesson: store.booking.currentLesson,
    options: store.booking.options,
    lessonDetails: store.booking.selectedLessonDetail,
    windowWidth: store.windowSize.width
});

const connector = connect(mapStateToProps);

export default injectIntl(connector(Options));
