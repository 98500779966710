export const VERSIONS = {
    Swisskischool: 0,
    SkiSchoolAlliance: 1,
    BookingSport: 2,
    BookingFormation: 3,
};
export const VERSIONS_URL: {
    Swisskischool: string;
    SkiSchoolAlliance: string;
    BookingSport: string;
    BookingFormation: string;
    [key: string]: string
} = {
    Swisskischool: process.env.REACT_APP_FRONTEND_SSS_URL || 'swisskischool.ch',
    SkiSchoolAlliance: process.env.REACT_APP_FRONTEND_SSA_URL || 'skischoolalliance.com',
    BookingSport: process.env.REACT_APP_FRONTEND_BS_URL || 'booking-sport.ch',
    BookingFormation: process.env.REACT_APP_FRONTEND_BF_URL || 'booking-formation.ch',
};
export const VERSIONS_NAMES = {
    Swisskischool: 'Swiss Ski School',
    SkiSchoolAlliance: 'Ski School Alliance',
    BookingSport: 'Booking Sport',
    BookingFormation: 'Booking Formation',
};
export const THEME_VERSIONS = {
    Swisskischool: 0,
    SkiSchoolAlliance: 1,
    VillarsSkiSchool: 2,
    DiableretsSkiSchool: 3, // Pure Trace
    BookingSport: 4,
    BookingSportRide2Peak: 5,
    BookingFormation: 6,
    BookingSportEvolution: 7,
};

export const TYPE_LESSON = {
    G: 'Group lessons',
    P: 'Private lessons',
};

export const TYPE_BOOKING = {
    G: 'Group lessons',
    P: 'Private lessons',
};

export const LESSON_FILTERS = {
    GROUP_LESSON: 'G',
    PRIVATE_LESSON: 'P',
    SKIING: 'ski',
    SNOWBOARD: 'snb',
    TELEMARK: 'tmk',
    OTHER: 'oth',
    SUMMER: 'sum',
    ROCKER: 'roc',
    MOUNTAINEERING: 'mnt',
    ICE: 'ice',
    TRAIL: 'tri',
    FORMATION: 'frm',
    WINTER: 'win',
    SNOW: 'snw',
    MINI_KID: 'MINI_KID',
    CHILD: 'CHILD',
    TEENAGER: 'TEENAGER',
    ADULT: 'ADULT',
    CRANS_KIDS_CRANS: 'kids-crans',
    CRANS_KIDS_GOLF: 'kids-golf',
    CRANS_KIDS_MONTANA: 'kids-montana',
    CRANS_SPECIAL: 'special',
    CRANS_PRIVATE: 'cm-private',
    CRANS_SNOWBOARD_G: 'snowboard-g',
    CRANS_SNOWBOARD: 'snowboard',
    CRANS_GROUP: 'cm-group',
    CRANS_OTHER: 'other',
    CRANS_KIDS_SUMMER: 'kids-summer',
    CRANS_BIKE: 'bike',
    GRIMENTZ: 'Grimentz',
    ZINAL: 'Zinal',
    CORBATIERE: 'Corbatière',
    BUGNENETS: 'Bugnenets',
    VUE_DES_ALPES: 'Vue-des-Alpes',
    CRET_DU_PUY: 'Crêt-du-Puy',
    SOMMARTEL: 'Sommartel',
    LAUCHERALP: 'Laucheralp',
    RIEDERALP: 'Riederalp',
    BELALP: 'Belalp',
    UNTERBACH: 'Unterbach',
    GRACHEN: 'Grächen',
    SAAS_FEE: 'Saas Fee',
    ZERMATT: 'Zermatt',
    GRIMENTZ_BF: 'Grimentz',
    LES_CROSETS: 'Les Crosets',
    NENDAZ: 'Nendaz',
    VEYSONNAZ: 'Veysonnaz',
    VERBIER: 'Verbier',

};

export const LESSON_KEYS = {
    SKIING: 'skiing',
    SNOWBOARD: 'snowboard',
    TELEMARK: 'telemark',
    SNOWSHOEING: 'snowshoeing',
    GROUP_LESSON: 'group_lesson',
    OTHER: 'other',
    PRIVATE_LESSON: 'private_lesson',
    MINI_KID: 'mini_kid',
    CHILD: 'child',
    TEENAGER: 'teenager',
    ADULT: 'adult',
};

export const URL_SCHOOL = {
    NAME: 'school',
    FILTER: 'school_id',
};

export const URL_ACTIVITY = {
    NAME: 'activity',
    FILTER: 'activity_type_in',
    SKI: 'ski',
    SNOWBOARD: 'snowboard',
    OTHER: 'other',
    SUMMER: 'summer',
    TELEMARK: 'telemark',
    ROCKER: 'roc',
    MOUNTAINEERING: 'mnt',
    ICE: 'ice',
    TRAIL: 'tri',
    FORMATION: 'frm',
    /* WINTER: '',
    SNOW: '', */
};

export const URL_LESSON = {
    NAME: 'lesson',
    FILTER: 'lesson_type_in',
    GROUP: 'group',
    PRIVATE: 'private',
};

export const URL_AGE = {
    NAME: 'age',
    FILTER: 'levels__ages',
    BABY: 'baby',
    KIDS: 'kids',
    TEENAGER: 'teenager',
    ADULT: 'adult',
};

export const URL_RESORT = {
    NAME: 'resort',
    FILTER: 'resort_in',
    GRIMENTZ: 'Grimentz',
    ZINAL: 'Zinal',
    CORBATIERE: 'Corbatière',
    BUGNENETS: 'Bugnenets',
    VUE_DES_ALPES: 'Vue-des-Alpes',
    CRET_DU_PUY: 'Crêt-du-Puy',
    SOMMARTEL: 'Sommartel',
    LAUCHERALP: 'Laucheralp',
    RIEDERALP: 'Riederalp',
    BELALP: 'Belalp',
    UNTERBACH: 'Unterbach',
    GRACHEN: 'Grächen',
    SAAS_FEE: 'Saas Fee',
    ZERMATT: 'Zermatt',
    GRIMENTZ_BF: 'Grimentz',
    LES_CROSETS: 'Les Crosets',
    NENDAZ: 'Nendaz',
    VEYSONNAZ: 'Veysonnaz',
    VERBIER: 'Verbier',

};

export const URL_DATE = {
    NAME: 'date',
    FILTER: 'date',
};

export const SCHOOLS_IDS_SSS = { // [name, products backend -> school id, department id] -> products
    // DEMO: ['demo', 36, undefined],
    GRIMENTZ: ['grimentz-zinal', 1, undefined],
    VERBIER: ['verbier', 2, undefined],
    CRANS_MONTANA: ['crans-montana', 3, undefined],
    NENDAZ: ['nendaz', 4, undefined],
    TZOUMAZ: ['latzoumaz', 8, undefined],
    ANZERE: ['anzere', 9, undefined],
    VEYSONNAZ: ['veysonnaz', 10, undefined],
    ST_LUC: ['st-luc', 11, undefined],
    THYON: ['thyon', 12, undefined],
    SION: ['sion', 13, undefined],
    EVOLENE: ['evolene', 14, undefined],
    AROLLA: ['arolla', 15, undefined],
    LAFORCLAZ: ['laforclaz', 16, undefined],
    VERCORIN: ['vercorin', 17, undefined],
    TORGON: ['torgon', 18, undefined],
    MORGINS: ['morgins', 19, undefined],
    MARECOTTES: ['marecottes', 20, undefined],
    ST_IMIER: ['st-imier', 21, undefined],
    NAX: ['nax', 22, undefined],
    OVRONNAZ: ['ovronnaz', 23, undefined],
    VILLARS: ['villars', 26, undefined],
    CHAMPERY: ['champery', 27, undefined],
    CHANDOLIN: ['chandolin', 32, undefined],
    NEUCHATEL_REGION: ['neuchatel-region', 29, undefined],
    CHATEAU_DOEX: ['chateau-doex', 30, undefined],
    LA_FOULY: ['lafouly', 33, undefined],
    LA_BERRA: ['laberra', 35, undefined],
};

export const SCHOOLS_IDS_SSA = {
    VILLARS: ['villars', 6, 'LK2'],
    DIABLERETS: ['diablerets', 7, 'SH2'],
};

export const SCHOOLS_IDS_BS = {
    GUIDESALPESVAUDOISES: ['guidesalpesvaudoises', 24, undefined],
    RIDE2PEAK: ['ride2peak', 28, undefined],
    EVOLUTION: ['evolution', 36, undefined],
};
export const SCHOOLS_IDS_BOOKINGFORMATION = {
    XX: ['pro', 34, undefined],
};

export const OPTIONS_DEPENDENCES = {
    DAYS: 'D',
    PARTICIPANTS: 'P',
    DAYS_AND_PARTICIPANTS: 'X',
    FIX: 'F',
    PERCENT: '%',
    QUANTITY: 'Q',
};

export const QUESTIONS_TYPES = {
    TEXT: 'X',
    BOOLEAN: 'B',
    INTEGER: 'N',
    DECIMAL: 'F',
    DATE: 'D',
    TIME: 'T',
    DATETIME: 'S',
    EMAIL: '@',
    PHONE: 'M',
    LIST: 'L',

};

export const SMARTPHONE_WIDTH = 900;
export const PAGES_ADDRESS = ['https://content.swisskischool.ch/pages/', 'https://content.skischoolalliance.com/skischoolalliance-pages/', 'https://content.booking-sport.ch/bs-pages/', 'https://content.booking-formation.ch/bf-pages/']; // order according to Version

export const IMAGES_ADDRESS = 'https://storage.googleapis.com/snowpros/images/products/';
export const IMAGES_EXTENSION = '-lessons-ski-siviez-cours-group-groep-collectif-prive-privat-ski-school-ecole-anzere-thyon-veysonnaz-la-tzoumaz-verbier-nendaz-veysonnaz-thyon-crans-montana-st-luc-grimentz-zinal-unterricht-ecole-valais-wallis-suisse-schweiz-switzerland.jpg';
export const IMAGES_EXTENSION_ALLIANCE = '-ski-snowboard-pure-trace-villars-gryon-mini-kids-club-cours-collectif-prive-privat-ski-school-lesson-ecole-schule-unterricht-vaud-suisse-switzerland.jpg';
export const IMAGES_EXTENSION_BS = '-ski-snowboard-guide-cours-collectif-prive-privat-ski-school-lesson-ecole-schule-unterricht-suisse-switzerland.jpg';
export const IMAGES_EXTENSION_BOOKING_FORMATION = '-ski-snowboard-guide-cours-collectif-prive-privat-ski-school-lesson-ecole-schule-unterricht-suisse-switzerland.jpg';

export const GOOGLE_STORAGE_API = 'https://storage.googleapis.com/';

export const GOOGLE_STORAGE_API_CGA = 'https://storage.googleapis.com/snowpros/cga/';
export const GOOGLE_STORAGE_API_MEDALS = 'https://storage.googleapis.com/snowpros/medailles/thumbnails/';

// export const LOGO_IMAGE_URL = ''; // TODO Set logo image url
export const WEBEVOLUTIONS_SAFERPAY_SCHOOLS = [2, 3, 4, 5, 6, 7, 8, 11, 14, 15, 16, 17, 18, 26, 30, 36, 37, 64, 81, 153];
// END > SCHOOLS WITH PRIVATE SAFERPAY ACCOUNT

export const SNOWLI_AI_SCHOOL_GROUP_KEYS:{
    [key: string]: string;
} = {
    verbier: 'verbier',
    // 'crans-montana': 'crans_montana'
};
export const LOGO_IMAGE_URL = '';
