import * as Sentry from '@sentry/browser';
import { isString } from 'lodash';
import moment from 'moment';
import themes from '../themes/themes';
import { School } from '../types/settings';
import {
    LESSON_FILTERS,
    SCHOOLS_IDS_BOOKINGFORMATION,
    SCHOOLS_IDS_BS, SCHOOLS_IDS_SSA, SCHOOLS_IDS_SSS,
    SNOWLI_AI_SCHOOL_GROUP_KEYS,
    THEME_VERSIONS,
    VERSIONS,
    VERSIONS_NAMES,
    VERSIONS_URL
} from './constants';

export const getTranslation = (list: any[], lang: string) => {
    const translation = list.find((t) => t.lang === lang);
    if (!translation) return list.find((t) => t.lang === 'en');
    return translation;
};

export const getSchoolsIds = () => {
    switch (getCurrentVersion()) {
        case VERSIONS.SkiSchoolAlliance:
            return SCHOOLS_IDS_SSA;
        case VERSIONS.BookingFormation:
            return SCHOOLS_IDS_BOOKINGFORMATION;
        case VERSIONS.BookingSport:
            return SCHOOLS_IDS_BS;
        case VERSIONS.Swisskischool:
            return SCHOOLS_IDS_SSS;
        default:
            Sentry.captureMessage(`error get_schools_ids : ${getCurrentVersion()}`);
            return SCHOOLS_IDS_SSS;
    }
};
export const domaineBaseHasSubdomainAlready = () => {
    if (process.env.REACT_APP_FRONTEND_DEFAULT_URL?.split('.').length === 3) {
        return ({
            max: 3,
            equal: 4,
            func: (splited: string[]) => {
                if (splited.length > 2) {
                    return `${splited[1]}.${splited[2]}.${splited[3]}`;
                }
                return `${splited[1]}.${splited[2]}`;
            },
            contentfunc: (url: string) => {
                const splitted = url.split('.');
                if (splitted.length > 2) {
                    return `${splitted[1]}.${splitted[2]}`;
                }
                return url;
            }
        });
    }
    return {
        max: 2, equal: 3, func: (splited: string[]) => `${splited[1]}.${splited[2]}`, contentfunc: (url: string) => `${url}`
    };
};

export const getDomain = () => {
    const values = domaineBaseHasSubdomainAlready();
    const splited = window.location.hostname.split('.');
    if (splited.length === values.equal) return values.func(splited);
    return window.location.hostname;
};

export const getSubDomain = () => {
    const values = domaineBaseHasSubdomainAlready();
    const splited = window.location.hostname.split('.');
    if (splited.length === values.equal) return splited[0];
    if (splited.length >= values.max && splited[1] === 'localhost') return splited[0];
    return undefined;
};

export const getCurrentVersion = (search_domain:string | undefined = undefined):number => {
    const domain = search_domain || getDomain();

    if (domain.endsWith(VERSIONS_URL.SkiSchoolAlliance)) {
        return VERSIONS.SkiSchoolAlliance;
    } if (domain.endsWith(VERSIONS_URL.BookingSport)) {
        return VERSIONS.BookingSport;
    } if (domain.endsWith(VERSIONS_URL.BookingFormation)) {
        return VERSIONS.BookingFormation;
    } if (domain.endsWith(VERSIONS_URL.Swisskischool)) {
        return VERSIONS.Swisskischool;
    }
    if (search_domain !== undefined) {
        return VERSIONS.Swisskischool;
    }

    Sentry.captureMessage(`error getCurrentVersion : ${domain}`);
    return getCurrentVersion(process.env.REACT_APP_FRONTEND_DEFAULT_URL);
};

export const getAllVersionUrls = () => {
    const values = domaineBaseHasSubdomainAlready();

    const allVersionUrls: string[] = [];
    for (const key in VERSIONS_URL) {
        if (Object.prototype.hasOwnProperty.call(VERSIONS_URL, key)) {
            if (values.max > 2) {
                allVersionUrls.push(values.contentfunc(VERSIONS_URL[key]));
            }
            allVersionUrls.push(`${VERSIONS_URL[key]}`);
        }
    }

    return allVersionUrls;
};
export const getVersionName = () => {
    const version = getCurrentVersion();
    switch (version) {
        case VERSIONS.SkiSchoolAlliance:
            return VERSIONS_NAMES.SkiSchoolAlliance;
        case VERSIONS.BookingFormation:
            return VERSIONS_NAMES.BookingFormation;
        case VERSIONS.BookingSport:
            return VERSIONS_NAMES.BookingSport;
        default:
            return VERSIONS_NAMES.Swisskischool;
    }
};

export const getSchoolById = (id: number) => {
    const school: any = Object.values(getSchoolsIds()).find((v) => v[1] === id);

    return school[0];
};

type FormatMessage = (props: { id: string }) => string;
export const getActivityNameFromType = (type: string | undefined, formatMessage:FormatMessage) => {
    switch (type) {
        case LESSON_FILTERS.SKIING:
            return formatMessage({ id: 'products.ski' });
        case LESSON_FILTERS.SNOWBOARD:
            return formatMessage({ id: 'products.snowboard' });
        case LESSON_FILTERS.TELEMARK:
            return formatMessage({ id: 'products.telemark' });
        case LESSON_FILTERS.OTHER:
            return formatMessage({ id: 'products.other' });
        case LESSON_FILTERS.SUMMER:
            return formatMessage({ id: 'products.summer' });
        case LESSON_FILTERS.ROCKER:
            return formatMessage({ id: 'products.rocker' });
        case LESSON_FILTERS.MOUNTAINEERING:
            return formatMessage({ id: 'products.mountaineering' });
        case LESSON_FILTERS.ICE:
            return formatMessage({ id: 'products.ice' });
        case LESSON_FILTERS.SNOW:
            return formatMessage({ id: 'products.snow' });
        case LESSON_FILTERS.WINTER:
            return formatMessage({ id: 'products.winter' });
        case LESSON_FILTERS.TRAIL:
            return formatMessage({ id: 'products.trail' });
        case LESSON_FILTERS.FORMATION:
            return formatMessage({ id: 'products.formation' });
        case undefined:
            // Lesson not yet loaded
            return '';
        default:
            Sentry.captureMessage(`Default case, type: ${type}`);
            return type;
    }
};

export const getLessonNameFromType = (type: string | undefined, formatMessage: FormatMessage) => {
    switch (type) {
        case LESSON_FILTERS.GROUP_LESSON:
            return formatMessage({ id: 'products.group' });
        case LESSON_FILTERS.PRIVATE_LESSON:
            return formatMessage({ id: 'products.private' });
        case undefined:
            // Lesson not yet loaded
            return '';
        default:
            Sentry.captureMessage(`Error undefined - default case getLessonNameFromType, type: ${type}`);
            return '-';
    }
};

export const decimalToTime = (
    decimal: number,
    formatMessage: FormatMessage,
    dayAwareness: boolean = true,
) => {
    let total = 0;
    if (!dayAwareness) {
        total = Math.floor(decimal);
    } else if (decimal > 24) {
        for (let i = 0; i < decimal; i += 24) {
            total += Math.floor(decimal) % 24;
        }
    } else {
        total = Math.floor(decimal) % 24;
    }

    const minutes: number = Math.round((decimal % 1) * 60);
    const minStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return `${total}${formatMessage({ id: 'booking.h' })}${minStr}`;
};

export const timeToDecimal = (time: number) => moment.duration(time);

/**
 * If the number has a 0 as the digit after the decimal point
 * @param {Number} number - the number to check and convert
 * @returns the number in the right type
 */
export const toInt = (number: any) => (isString(number) ? parseInt(number, 10) : number);

export const cransParamsToBody = (params: any, formatMessage: FormatMessage) => {
    const body: any = {};
    Object.assign(body, params);
    if (body.lesson_type_in) body.lesson_type_in = body.lesson_type_in.map((value: any) => formatMessage({ id: `crans.${value}` }));
    return body;
};

export const returnRefinedURL = (key: any, url: string) => {
    // separating the key-value ('search') portion of the URL from the rest:
    const urlParts = url.split('?');
    // if we have only a single array-element, or if the key to remove
    // is not found in the URL, we quit here and return the same unchanged URL:
    if (urlParts.length === 1 || url.indexOf(key) === -1) {
        // there were no parameters, or the
        // key wasn't present
        return url;
    }

    // otherwise, we split the key-value string on the '&' characters,
    // for an array of key=value strings:
    const keyValues = urlParts[1].split('&');
    // filtering that array:
    const refinedKeyValues = keyValues.filter((keyValuePair) => keyValuePair.indexOf(key) !== 0).join('&');
    // keeping only those array elements that don't /start with/
    // the key to be removed:
    // joining the key=value pairs back into a string:

    // returning the refined URL:
    return `${urlParts[0]}?${refinedKeyValues}`;
};

export const isURL = (str: string) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
        + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
        + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
        + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
};

export const getPath = () => {
    const splitted = window.location.href.split('/');
    let path = '/';
    for (let i = 3; i < splitted.length; i++) {
        path += splitted[i];
        if ((i + 1) !== splitted.length) {
            path += '/';
        }
    }
    return path;
};

export const getCurrentLanguage = () => {
    const path = getPath();
    if (path === undefined || path === null || path === '') {
        return 'fr';
    }
    const splitted = path.split('/');

    const lang = splitted[1].toLowerCase();
    if (['fr', 'en', 'it', 'de'].includes(lang)) {
        return lang;
    }
    return 'fr';
};

export interface ImgWithFallbackProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    fallback: string;
    containerClasses?: string;
    type?: string;
}

type Props = ImgWithFallbackProps;

// const defaultProps = {
//     containerClasses: '',
//     type: 'image/webp',
// };

export function ImgWithFallback({
    src,
    fallback,
    containerClasses = '',
    type = 'image/webp',
    ...delegated
}: Props) {
    return (
        <picture className={containerClasses}>
            <source srcSet={src} type={type} />
            {/* eslint-disable-next-line jsx-a11y/alt-text, react/jsx-props-no-spreading */}
            <img src={fallback} {...delegated} />
        </picture>
    );
}

// ImgWithFallback.defaultProps = defaultProps;

export const getCurrentThemeVersion = () => {
    const domain = getDomain();
    const subdomain = getSubDomain();

    if (domain.includes(VERSIONS_URL.SkiSchoolAlliance)) {
        if (subdomain && subdomain.includes('villars')) return THEME_VERSIONS.VillarsSkiSchool;
        if (subdomain && subdomain.includes('diablerets')) return THEME_VERSIONS.DiableretsSkiSchool;
        return THEME_VERSIONS.SkiSchoolAlliance;
    } if (domain.includes(VERSIONS_URL.BookingSport)) {
        if (subdomain && subdomain.includes('ride2peak')) return THEME_VERSIONS.BookingSportRide2Peak;
        if (subdomain && subdomain.includes('evolution')) return THEME_VERSIONS.BookingSportEvolution;
        return THEME_VERSIONS.BookingSport;
    } if (domain.includes(VERSIONS_URL.BookingFormation)) {
        return THEME_VERSIONS.BookingFormation;
    } if (domain.includes(VERSIONS_URL.Swisskischool)) {
        return THEME_VERSIONS.Swisskischool;
    }
    Sentry.captureMessage(`error getCurrentVersion : ${subdomain} - ${domain}`);
    return THEME_VERSIONS.Swisskischool;
};

export const goToDefaultDomain = () => {
    const { href, protocol } = window.location;
    const splited = href.split('.');
    if (splited.length === 3) {
        const tmp = splited[2].split('/');
        window.location.href = `${protocol}//${splited[1]}.${tmp[0]}/${tmp[1]}/`;
    } else { window.location.href = href; }
};

// export const changeSubDomain = (subDomain) => {
//     const { hostname } = window.location;
//     const splited = hostname.split(".");
//     if (splited.length === 3)
// window.location.href = window.location.href.replace(splited[0], subDomain);
//     else window.location.href = window.location.href.replace(
// hostname, `${subDomain}.${hostname}`);
// }

export const changeSubDomain = (subDomain: string | undefined) => {
    const {
        href, host, pathname, protocol,
    } = window.location;
    if (!subDomain) return;

    const splited = href.split('.');
    if (splited.length === 3) {
        const tmp = splited[2].split('/');
        window.location.href = `${protocol}//${subDomain}.${splited[1]}.${tmp[0]}/${tmp[1]}/`;
        // tmp = "/" + tmp[1] + "/";
        // let tmpHref = href.replace(splited[0], subDomain);
        // console.log("t", tmpHref);
        // tmpHref = tmpHref.replace(pathname, tmp);
        // console.log("t", tmpHref);
        // window.location.href = protocol + "//" + tmpHref;
    } else {
        const tmpTable = pathname.split('/');
        const tmp = `/${tmpTable[1]}/`;
        window.location.href = `${protocol}//${subDomain}.${host}${tmp}`;
    }
};

/**
 * Apply the correct theme corresponding to the current domain
 */
export const applyTheme = () => {
    const version = getCurrentThemeVersion();
    const theme = themes[version];
    const root = document.getElementById('body');
    Object.keys(theme).forEach((key) => {
        root?.style.setProperty(key, theme[key as keyof typeof theme]);
    });
};

export const getTheme = () => {
    const version = getCurrentThemeVersion();
    const theme = themes[version];
    return theme;
};
export const getLessonCardFromLesson = (lessons: School[], language: string) => lessons.map((lesson) => {
    const lessonCard = {
        id: lesson.id,
        name: getTranslation(lesson.translations, language)?.name,
        image: lesson.image,
        desc: getTranslation(lesson.translations, language)?.desc,
        path: lesson.path,
        translations: lesson.translations.map((t) => ({
            id: t.id, desc: t.desc, name: lesson.name, lang: t.lang
        }))

    };
    if (lessonCard.path === undefined || lessonCard.path === '') lessonCard.path = lesson.path;
    if (lessonCard.desc === undefined || lessonCard.desc === '') lessonCard.desc = lesson.desc ? lesson.desc : '';
    if (lessonCard.image === undefined || lessonCard.image === '') lessonCard.image = lesson.image;
    if (lessonCard.name === undefined || lessonCard.name === '') lessonCard.name = lesson.title;

    return lessonCard;
});
export const getLessonCardFromSchools = (schools: School[], language: string) => schools.map((school) => {
    const lessonCard = {
        id: school.id,
        name: school.name,
        image: school.image,
        desc: school.desc ? getTranslation(school.translations, language)?.desc : '',
        path: school.path,
        translations: school.translations.map((t) => ({
            id: t.id, desc: t.desc, name: school.name, lang: t.lang
        }))
    };
    if (lessonCard.path === undefined || lessonCard.path === '') lessonCard.path = school.path;
    if (lessonCard.desc === undefined || lessonCard.desc === '') lessonCard.desc = school.desc ? school.desc : '';
    if (lessonCard.image === undefined || lessonCard.image === '') lessonCard.image = school.image;
    if (lessonCard.name === undefined || lessonCard.name === '') lessonCard.name = school.title;

    return lessonCard;
});

export const getSnowliGroupKeyForSchool = (school: string | undefined) => {
    if (school === undefined) {
        return undefined;
    }
    const groupKey = SNOWLI_AI_SCHOOL_GROUP_KEYS[school];
    return groupKey;
};
